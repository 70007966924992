(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function (global, factory) {
    if (typeof define === "function" && define.amd) {
        define([], factory);
    } else if (typeof exports !== "undefined") {
        factory();
    } else {
        var mod = {
            exports: {}
        };
        factory();
        global.login = mod.exports;
    }
})(this, function () {
    'use strict';

    var validate = {
        email: function email(event) {
            var value = event.target.value;
            var regEmail = /^[\w-\.]+@[\w-]+\.[a-z]{2,3}$/i;
            if (regEmail.test(value)) {
                event.target.classList.remove('error');
                return true;
            }
            event.target.classList.add('error');
            return false;
        },
        password: function password(event) {
            var value = event.target.value;
            if (value.length >= 6) {
                event.target.classList.remove('error');
                return true;
            } else {
                event.target.classList.add('error');
                return false;
            }
        },
        name: function name(event) {
            var value = event.target.value;
            var regName = /^[a-zA-Zа-яА-ЯёЁ'][a-zA-Z-а-яА-ЯёЁ' ]+[a-zA-Zа-яА-ЯёЁ']?$/;
            if (regName.test(value)) {
                event.target.classList.remove('error');
                return true;
            }
            event.target.classList.add('error');
            return false;
        }
    };

    (function () {
        var signUp = new Vue({
            el: '#login-page',
            data: {
                firstName: '',
                lastName: '',
                email: '',
                password: ''
            },
            methods: {
                nameChange: function nameChange(e) {
                    validate.name(e);
                    console.log(signUp);
                },
                changeEmail: function changeEmail(e) {
                    validate.email(e);
                },
                changePassword: function changePassword(e) {
                    validate.password(e);
                },
                togglePopup: function togglePopup() {
                    document.querySelector('.popup-bg').classList.toggle('inActive');
                    document.querySelector('.set-password').classList.toggle('inActive');
                }
            }
        });
    })();
});
}).call(this,require("FT5ORs"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_ce9fd008.js","/")